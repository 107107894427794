#tab-indicator {
    position: absolute;
    left: 5px;
    bottom: -10px;
    width: 90px;
    height: 5px;
    background: purple;
}

#cms-section-container {
    border-radius: 8px;
    padding: 15px 15px 25px;
    margin-top: 35px;
    box-shadow: 2px 5px 10px -5px grey;
}

#cms-section-header {
    font-size: 20px;
    font-weight: bold;
    color: #0074C0;
}

#cms-section-body {
    display: grid;
    grid-template-columns: auto auto;
}

#cms-label {
    margin: 15px 15px 0px 0px;
}